import { useCallback, useEffect, useRef, useState } from "react";
import { Space, SpaceEvent } from "@mux/spaces";
import Participant from "./participant";

import "./App.css";

const SpaceComp = ({ JWT }) => {
  const spaceRef = useRef(null);
  const [localParticipant, setLocalParticipant] = useState();
  // Our list of participants in React state
  const [participants, setParticipants] = useState([]);

  // Set the participant state to the current participants and the new one
  const addParticipant = useCallback(
    (participant) => {
      setParticipants((currentParticipants) => [
        ...currentParticipants,
        participant,
      ]);
    },
    [setParticipants]
  );

  // Filter out the participant who left and set the participant state
  const removeParticipant = useCallback(
    (participantLeaving) => {
      setParticipants((currentParticipants) =>
        currentParticipants.filter(
          (currentParticipant) =>
            currentParticipant.connectionId !== participantLeaving.connectionId
        )
      );
    },
    [setParticipants]
  );

  useEffect(() => {
    debugger;
    const space = new Space(JWT);

    // // Setup event listeners for other people joining and leaving
    space.on(SpaceEvent.ParticipantJoined, addParticipant);
    space.on(SpaceEvent.ParticipantLeft, removeParticipant);

    spaceRef.current = space;

    return () => {
      // Cleanup event listeners when the component is unmounted
      space.off(SpaceEvent.ParticipantJoined, addParticipant);
      space.off(SpaceEvent.ParticipantLeft, removeParticipant);
    };
  }, [JWT, addParticipant, removeParticipant]);

  const join = useCallback(async () => {
    // Join the Space
    let localParticipant = await spaceRef.current?.join?.();

    if (!localParticipant) {
      return;
    }
    // Get and publish our local tracks
    let localTracks = await localParticipant.getUserMedia({
      audio: {}, //true,
      video: {}, //true,
    });
    await localParticipant.publishTracks(localTracks);

    // Set the local participant so it will be rendered
    setLocalParticipant(localParticipant);
  }, []);

  return (
    <div className="App">
      <button onClick={join}>Join</button>

      {localParticipant && (
        <Participant
          key={localParticipant.connectionId}
          participant={localParticipant}
        />
      )}
      {participants.map((participant) => {
        return (
          <Participant
            key={participant.connectionId}
            participant={participant}
          />
        );
      })}
    </div>
  );
}

export default SpaceComp;
