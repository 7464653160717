import queryString from 'query-string';
import Space from './space';

import "./App.css";

const parsed = queryString.parse(document.location.search);
const JWT = parsed.JWT;

const AppWrapper = () => {
  if (!JWT) {
    return (
      <div>
        No JWT specified
      </div>
    );
  }
  return (<Space JWT={JWT} />);
}

export default AppWrapper;
